import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import TemplateScreen from '../templateScreen/TemplateScreen'
import { ErrorApiDefault, IErrorApi } from '../validateOTP/useValidateOTP'
import BackDropLoadApi from '../backDropLoadApi/BackDropLoadApi'
import { IDeclineRequest } from './useDeclineOTP'

const buttonStyle = { padding: '0.5rem', margin: '0.5rem' }
export interface IDeclineOTPProps {
  documentName: string
  patientFullName: string
  date: string
  declineRequest: IDeclineRequest
  setDeclineRequest: (value: IDeclineRequest) => void
  isLoading: boolean
  errorApi: IErrorApi
  isDeclined: boolean
  sendDeclineRequest: () => void
  setErrorApi: (value: IErrorApi) => void
  codeFile: string
}
const DeclineOTP: React.FC<IDeclineOTPProps> = props => {
  const navigate = useNavigate()
  const handleChange = (event: SelectChangeEvent) => {
    props.setDeclineRequest({
      ...props.declineRequest,
      Reason: event.target.value
    })
  }

  const confirmedSend = () => {
    void Swal.fire({
      title: 'Esta seguro de rechazar la firma del documento?',
      text: '¡No podrás revertir esto.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, Rechazar!',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.isConfirmed) {
        props.sendDeclineRequest()
      }
    })
  }

  React.useEffect(() => {
    if (props.isDeclined) {
      void Swal.fire('Documento Rechazado', 'El documento ha sido Rechazado con exito', 'success').then(result => {
        if (result.isConfirmed) {
          navigate(`/view?id=${props.codeFile}`)
        }
      })
    }
  }, [props.isDeclined])

  React.useEffect(() => {
    if (props.errorApi?.errorCode !== 0) {
      void Swal.fire('Error', props.errorApi.messageError, 'error').then(result => {
        if (result.isConfirmed) {
          props.setErrorApi(ErrorApiDefault)
        }
      })
    }
  }, [props.errorApi])

  return (
    <TemplateScreen {...props}>
      <BackDropLoadApi open={props.isLoading} />
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='demo-simple-select-helper-label'>Motivo</InputLabel>
          <Select
            labelId='reason-rejection-select-helper-label'
            id='reason-rejection-select-helper'
            value={props.declineRequest.Reason}
            label='Motivo'
            onChange={handleChange}
          >
            <MenuItem value=''>
              <em>Selecione</em>
            </MenuItem>
            <MenuItem value={'La información es incorrecta'}>La información es incorrecta</MenuItem>
            <MenuItem value={'No soy esa persona'}>No soy esa persona</MenuItem>
            <MenuItem value={'Ya he firmado'}>Ya he firmado</MenuItem>
            <MenuItem value={'Otro'}>Otro</MenuItem>
          </Select>
          <FormHelperText>Selecione el motivo por el cual no firma el documento</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1, justifyContent: 'center', alignItems: 'center' }}>
          <TextField
            sx={{ m: 1, width: '75%' }}
            id='outlined-multiline-static'
            label='Observación'
            multiline
            rows={3}
            onChange={e =>
              props.setDeclineRequest({
                ...props.declineRequest,
                ObservationRejection: e.target.value
              })
            }
            value={props.declineRequest.ObservationRejection}
          />
        </FormControl>
        <Button
          size='large'
          disabled={props.declineRequest.Reason === '' || props.declineRequest.ObservationRejection === ''}
          variant='contained'
          color='error'
          fullWidth
          sx={buttonStyle}
          onClick={() => confirmedSend()}
          startIcon={<CloseIcon />}
        >
          Rechazar
        </Button>
      </Grid>
    </TemplateScreen>
  )
}

export default DeclineOTP
