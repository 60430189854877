import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Fab } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useOTPContext } from '../../contextProviders/otpProvider'
import BackDropLoadApi from '../backDropLoadApi/BackDropLoadApi'
import { UseViewPDF } from './useViewPDF'
import ViewPDF from './ViewPDF'

interface stateType {
  from: string
}

const ViewPDFContainer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { signingProcess, signatoryData } = useOTPContext()
  const [fileCodeParams] = React.useState(searchParams.get('id')?.replaceAll(' ', '+'))
  const state = location.state as stateType

  const { isLoading, base64, getBase64File } = UseViewPDF()

  const requestToObtainCode = {
    IdGrupoEmpresarial: Number(signingProcess.BusinessGroupId),
    IdEntidad: Number(signingProcess.CompanyId),
    IdSede: Number(signingProcess.HeadquarterId),
    BaseDatos: signingProcess.DataBaseName,
    ExternalId: signingProcess.ExternalId,
    CodigoArchivo: fileCodeParams || '',
    SignerId: Number(signatoryData.Id),
    BackgroundProcessLogId: Number(signingProcess.BackgroundProcessLogId)
  }

  React.useEffect(() => {
    if (fileCodeParams !== undefined) {
      getBase64File(requestToObtainCode)
    }
  }, [fileCodeParams])

  if (isLoading) {
    return <BackDropLoadApi open={isLoading} />
  } else {
    return (
      <div>
        {state?.from === 'main' && (
          <Fab
            variant='extended'
            sx={{ m: 1, position: 'fixed',}}
            size='small'
            color='primary'
            aria-label='add'
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon sx={{ mr: 1 }} />
            Regresar
          </Fab>
        )}

        <ViewPDF base={base64} />
      </div>
    )
  }
}

export default ViewPDFContainer
