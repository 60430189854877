import { AxiosError } from 'axios'
import React from 'react'
import { API_URL } from '../../config'
import useAxios from '../../utils/useAxios'
import { ErrorApiDefault, ResponseApi } from '../validateOTP/useValidateOTP'

export interface IDeclineRequest {
  IdGrupoEmpresarial: number
  IdEntidad: number
  IdSede: number
  BaseDatos: string
  ExternalId: string
  SignerId: number
  BackgroundProcessLogId: number
  ObservationRejection: string
  Reason: string
}

const declineRequestDefault = {
  IdGrupoEmpresarial: 0,
  IdSede: 0,
  IdEntidad: 0,
  BaseDatos: '',
  ExternalId: '',
  SignerId: 0,
  BackgroundProcessLogId: 0,
  ObservationRejection: '',
  Reason: ''
}

export const UseDeclineOTP = () => {
  const { axios } = useAxios()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorApi, setErrorApi] = React.useState(ErrorApiDefault)
  const [declineRequest, setDeclineRequest] = React.useState(declineRequestDefault)
  const [isDeclined, setIsDeclined] = React.useState(false)
  const [codeFile, setCodeFile] = React.useState('')

  const sendDeclineRequest = () => {
    setIsLoading(true)
    axios
      .post(`${API_URL ?? ''}api/v1/otp/reject`, declineRequest)
      .then(response => {
        const data = response.data as ResponseApi
        setCodeFile(data.Content)
        setIsDeclined(true)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 423) {
          setErrorApi({
            errorCode: error.response?.status ?? 423,
            messageError: 'El proceso ya no puede ser modificado'
          })
        } else {
          setErrorApi({
            errorCode: error.response?.status ?? 500,
            messageError: 'Error al tratar de rechazar la firma'
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  return { sendDeclineRequest, declineRequest, setDeclineRequest, isLoading, errorApi, setErrorApi, isDeclined, codeFile }
}
