import { AxiosError } from 'axios'
import React from 'react'
import { API_URL } from '../../config'
import useAxios from '../../utils/useAxios'

export interface IRequestToObtainCode {
  IdGrupoEmpresarial: number
  IdEntidad: number
  IdSede: number
  BaseDatos: string
  BackgroundProcessLogId: number
  ExternalId: string
  SignerId: number
}

export interface IClientRequestValidate {
  IdGrupoEmpresarial: number
  IdEntidad: number
  IdSede: number
  BaseDatos: string
  VerificationCode: string
  SignerId: number
}

export interface ResponseApi {
  TransationId: number
  BackgroundProcessLogId: number
  ElapsedMilliseconds: number
  Status: boolean
  Message: string
  Content: string
}

export interface IErrorApi {
  messageError: string
  errorCode: number
}

export const ErrorApiDefault = {
  messageError: '',
  errorCode: 0
}

export const UseValidateOTP = () => {
  const { axios } = useAxios()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSignedDocument, setIsSignedDocument] = React.useState(false)
  const [codeFile, setCodeFile] = React.useState('')
  const [errorApi, setErrorApi] = React.useState(ErrorApiDefault)

  const sendRequest = (requestToObtainCode: IRequestToObtainCode) => {
    setIsLoading(true)
    axios
      .post(`${API_URL ?? ''}api/v1/otp/generateaccesscode`, requestToObtainCode)
      .catch((error: AxiosError) => {
        const errorCode = error.response?.status ?? 500
        let messageError = 'El servicio de verificación esta presentando problemas'

        if (errorCode === 423) {
          messageError = 'El proceso ya no puede ser modificado'
        }

        setErrorApi({
          errorCode,
          messageError
        })
      })
      .finally(() => setIsLoading(false))
  }

  const validateCode = (clientRequestValidate: IClientRequestValidate) => {
    setIsLoading(true)
    axios
      .post(`${API_URL ?? ''}api/v1/otp/validatecode`, clientRequestValidate)
      .then(response => {
        const data = response.data as ResponseApi
        setCodeFile(data.Content)
        setIsSignedDocument(true)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          setErrorApi({ errorCode: 404, messageError: 'Código no valido, intente de nuevo' })
        }
        if (error.response?.status === 423) {
          setErrorApi({
            errorCode: error.response?.status ?? 423,
            messageError: 'El proceso ya no puede ser modificado'
          })
        }

        if ((error.response?.status ?? 500) >= 500) {
          setErrorApi({
            errorCode: error.response?.status ?? 500,
            messageError: 'El servicio de verificación esta presentando problemas'
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  return { sendRequest, validateCode, isLoading, isSignedDocument, errorApi, setErrorApi, codeFile }
}
