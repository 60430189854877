import React from 'react'
import { useSearchParams } from 'react-router-dom'
import HistoryOTP, { IHistoryOTPProps } from './HistoryOTP'
import { UseHistoryOTP } from './useHistoryOTP'

const HistoryOTPContainer: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { isLoading, historyOTP, getHistoryOTP, errorApi, setErrorApi } = UseHistoryOTP()
  const [historyId] = React.useState(searchParams.get('id') ?? '')

  React.useEffect(() => {
    if (historyId !== '') {
      getHistoryOTP(historyId)
    }
  }, [historyId])

  const historyOTPprops: IHistoryOTPProps = {
    documentName: historyOTP.DocumentName,
    patientFullName: '',
    date: '',
    isLoading,
    errorApi,
    setErrorApi,
    registers: historyOTP.Registers
  }

  return <HistoryOTP {...historyOTPprops} />
}

export default HistoryOTPContainer
