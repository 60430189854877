import React, { useEffect, useState } from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'

import '@react-pdf-viewer/core/lib/styles/index.css'
const pdfContentType = 'application/pdf'

interface IViewPDFProps {
  base: string
}
const ViewPDF: React.FC<IViewPDFProps> = ({ base }) => {
  const [url, setUrl] = useState('')

  const base64toBlob = (base64: string) => {
    const bytes = atob(base64)
    let length = bytes.length
    const out = new Uint8Array(length)

    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }

    return new Blob([out], { type: pdfContentType })
  }

  useEffect(() => {
    if (base !== '' ) {
      setUrl(URL.createObjectURL(base64toBlob(base)))
    }
  }, [base])

  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
      <Viewer fileUrl={url} />
    </Worker>
  )
}

export default ViewPDF
