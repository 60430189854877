import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const paperStyle = { padding: '1rem', margin: '1rem auto' }

const NotFound: React.FC = () => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ marginTop: '1rem', height: '80vh' }}
    >
      <Grid item md={5} xs={12}>
        <Paper elevation={10} style={paperStyle}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid container item xs={12} justifyContent='center' alignItems='center'>
              <ErrorOutlineIcon sx={{ fontSize: '8rem' }} color='error' />
              <Typography
                color='error'
                variant='h4'
                align='center'
                component='h4'
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  '@media (min-width:670px)': {
                    fontSize: '2.5rem'
                  }
                }}
              >
                PROCESO DE FIRMA NO ENCONTRADO
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent='center' alignItems='center'>
              <Typography variant='body1' gutterBottom>
                El sitio no existe, o su session ya expiró.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default NotFound
