import { AxiosError } from 'axios'
import React from 'react'
import { API_URL } from '../../config'
import useAxios from '../../utils/useAxios'
import { ErrorApiDefault, ResponseApi } from '../validateOTP/useValidateOTP'

export interface IClientRequestDownloadFile {
  IdGrupoEmpresarial: number
  IdEntidad: number
  IdSede: number
  BaseDatos: string
  CodigoArchivo: string
  SignerId: number
}

export const UseViewPDF = () => {
  const { axios } = useAxios()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorApi, setErrorApi] = React.useState(ErrorApiDefault)
  const [base64, setBase64] = React.useState('')

  const getBase64File = (request: IClientRequestDownloadFile) => {
    setIsLoading(true)
    axios
      .post(`${API_URL ?? ''}api/v1/otp/download`, request)
      .then(response => {
        const data = response.data as ResponseApi
        setBase64(data.Content)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          setErrorApi({
            errorCode: error.response?.status ?? 400,
            messageError: 'Solicitud no válida'
          })
        } else {
          setErrorApi({
            errorCode: error.response?.status ?? 500,
            messageError: 'Error al tratar el documento'
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  return { isLoading, errorApi, base64, getBase64File }
}
