import { Link, Typography } from '@mui/material'
import React from 'react'

const Copyright: React.FC = () => {
  return (
    <Typography variant='body2' color='white' align='center' sx={{ mt: 8, pb: 4 }}>
      {'Copyright © '}
      <Link color='inherit' href='https://www.imedicalservices.com/'>
        iMedical Services
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Copyright
