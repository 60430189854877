import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useOTPContext } from '../../contextProviders/otpProvider'
import MainOTP, { IMainOTPProps } from './MainOTP'

const rootPath = '/'

export interface ICustomJwtPayload extends JwtPayload {
  Id: string
  Guid: string
  EnterpriseGroupId: string
  EntityId: string
  EntityName: string
  CompanyOfficeId: string
  CompanyOfficeName: string
  Tenant: string
  ExternalId: string
  DocumentName: string
  URLFileTemp: string
  BackgroundProcessLogId: string
  SignerId: string
  SignerUserFirstName: string
  SignerUserSecondName: string
  SignerUserFirstLastName: string
  SignerUserSecondLastName: string
  CreationDate: string
}

const isTokenExpired = (decodedToken: ICustomJwtPayload) => {
  const expirationDate = new Date((decodedToken.exp ?? 0) * 1000)
  const currentDate = new Date()

  return expirationDate < currentDate
}
// eslint-disable-next-line sonarjs/cognitive-complexity
const MainOTPContainer: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const RedirectQueryParam = 'redirect'

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const statePath = useLocation()

  // The redirect query param is created for the K8s Ingress when a URL is visited directly
  // React Router does not know about this and we need to translate to the Router manager.
  const redirectQueryParam = query.get(RedirectQueryParam) || statePath.pathname

  const { signingProcess, setSigningProcess, signatoryData, setSignatoryData } = useOTPContext()

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (searchParams.get('id') !== '') {
      const jwt: ICustomJwtPayload = jwtDecode(searchParams.get('id') || '')

      if (isTokenExpired(jwt)) {
        navigate(`/notfound`)
      }
      setSigningProcess({
        DocumentName: jwt.DocumentName ?? '',
        Guid: jwt.Guid?? '',
        FileUrl: jwt.URLFileTemp ?? '',
        EntityName: jwt.EntityName ?? '',
        CompanyOfficeName: jwt.CompanyOfficeName ?? '',
        BusinessGroupId: jwt.EnterpriseGroupId ?? '',
        CompanyId: jwt.EntityId ?? '',
        HeadquarterId: jwt.CompanyOfficeId ?? '',
        DataBaseName: jwt.Tenant ?? '',
        ExternalId: jwt.ExternalId ?? '',
        BackgroundProcessLogId: jwt.BackgroundProcessLogId ?? '',
        CreationDate: jwt.CreationDate ?? ''
      })
      setSignatoryData({
        Id: jwt.SignerId ?? '',
        SignerUserFirstName: jwt.SignerUserFirstName ?? '',
        SignerUserSecondName: jwt.SignerUserSecondName ?? '',
        SignerUserFirstLastName: jwt.SignerUserFirstLastName ?? '',
        SignerUserSecondLastName: jwt.SignerUserSecondLastName ?? '',
        SignerUserFullName: `${signatoryData.SignerUserFirstName} ${signatoryData.SignerUserSecondName} ${signatoryData.SignerUserFirstLastName} ${signatoryData.SignerUserSecondLastName}`
      })
    }
  }, [])

  const mainOTPprops: IMainOTPProps = {
    documentName: signingProcess.DocumentName,
    patientFullName: signatoryData.SignerUserFullName,
    filePath: signingProcess.Guid,
    date: signingProcess.CreationDate
  }

  return redirectQueryParam !== rootPath ? (
    <Navigate to={redirectQueryParam} state={{ from: redirectQueryParam }} />
  ) : (
    <MainOTP {...mainOTPprops} />
  )
}

export default MainOTPContainer
