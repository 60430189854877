import React from 'react'
import { useOTPContext } from '../../contextProviders/otpProvider'
import { UseValidateOTP } from './useValidateOTP'
import ValidateOTP, { IValidateOTPProps } from './ValidateOTP'

const ValidateOTPConatainer: React.FC = () => {
  const [otp, setOtp] = React.useState('')
  const { signingProcess, signatoryData } = useOTPContext()

  const { sendRequest, validateCode, isLoading, isSignedDocument, errorApi, setErrorApi, codeFile } = UseValidateOTP()

  const requestToObtainCode = {
    IdGrupoEmpresarial: Number(signingProcess.BusinessGroupId),
    IdEntidad: Number(signingProcess.CompanyId),
    IdSede: Number(signingProcess.HeadquarterId),
    BaseDatos: signingProcess.DataBaseName,
    ExternalId: signingProcess.ExternalId,
    FileId: signingProcess.ExternalId,
    SignerId: Number(signatoryData.Id),
    BackgroundProcessLogId: Number(signingProcess.BackgroundProcessLogId)
  }

  const clientRequestValidate = {
    IdGrupoEmpresarial: Number(signingProcess.BusinessGroupId),
    IdEntidad: Number(signingProcess.CompanyId),
    IdSede: Number(signingProcess.HeadquarterId),
    BaseDatos: signingProcess.DataBaseName,
    ExternalId: signingProcess.ExternalId,
    SignerId: Number(signatoryData.Id),
    VerificationCode: otp,
    BackgroundProcessLogId: Number(signingProcess.BackgroundProcessLogId)
  }
  React.useEffect(() => {
    sendRequest(requestToObtainCode)
  }, [])

  const validateVerificationCode = () => {
    validateCode(clientRequestValidate)
  }

  const validateOTPprops: IValidateOTPProps = {
    documentName: signingProcess.DocumentName,
    patientFullName: signatoryData.SignerUserFullName,
    date: signingProcess.CreationDate,
    otp,
    setOtp,
    isLoading,
    validateVerificationCode,
    isSignedDocument,
    errorApi,
    setErrorApi,
    codeFile
  }

  return <ValidateOTP {...validateOTPprops} />
}

export default ValidateOTPConatainer
