import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { TOKEN_API } from '../config'

const useAxios = () => {

  const onRequest = (
    config: InternalAxiosRequestConfig<any>
  ): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> => {
    config.headers.set('Token', TOKEN_API)
    
    return config
  }

  const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
  }

  axios.interceptors.request.use(onRequest, onRequestError)

  return { axios }
}

export default useAxios
