import React from 'react'

import './App.css'
import Copyright from './components/copyright/Copyright'
import RouterApp from './router/RouterOTP'

const App: React.FC = () => {
  return (
    <>
      <div className='app'>
        <RouterApp />
        <Copyright />
      </div>
    </>
  )
}

export default App
