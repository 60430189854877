import { AxiosError } from 'axios'
import React from 'react'
import { API_URL } from '../../config'
import useAxios from '../../utils/useAxios'
import { ErrorApiDefault } from '../validateOTP/useValidateOTP'

export interface Register {
  CreationDate: string
  SignerUserFirstName: string
  SignerUserSecondName: string
  SignerUserFirstLastName: string
  SignerUserSecondLastName: string
  SignerUserEmail: string
  SignerUserTelephoneNumber: string
  SignerUserIdentificationNumber: string
  SignerUserIdentificationType: string
  StateProcessId: string
  StateProcessName: string
  ProcessNote: string
}

export interface IHistory {
  DocumentName: string
  EntityName: string
  CompanyOfficeName: string
  Registers: Register[]
}

export const historyDefault: IHistory = {
  DocumentName: '',
  EntityName: '',
  CompanyOfficeName: '',
  Registers: []
}

export const UseHistoryOTP = () => {
  const { axios } = useAxios()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorApi, setErrorApi] = React.useState(ErrorApiDefault)

  const [historyOTP, setHistoryOTP] = React.useState<IHistory>(historyDefault)

  const getHistoryOTP = (idProcessSigantured: string) => {
    setIsLoading(true)
    axios
      .get(`${API_URL ?? ''}api/v1/otp/history?id=${idProcessSigantured}`)
      .then(response => {
        setHistoryOTP(response.data as IHistory)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          setErrorApi({
            errorCode: error.response?.status ?? 400,
            messageError: 'Solicitud no válida'
          })
        } else {
          setErrorApi({
            errorCode: error.response?.status ?? 500,
            messageError: 'Error al tratar de obtener el historial'
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  return { isLoading, historyOTP, getHistoryOTP, errorApi, setErrorApi }
}
