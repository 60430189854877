import React from 'react'
import useLocalStorage from '../utils/useLocalStorage'

export interface ISignatoryData {
  Id: string
  SignerUserFullName: string
  SignerUserFirstName: string
  SignerUserSecondName: string
  SignerUserFirstLastName: string
  SignerUserSecondLastName: string
}

export interface ISigningProcess {
  DocumentName: string
  Guid: string
  FileUrl: string
  EntityName: string
  CompanyOfficeName: string
  BusinessGroupId: string
  CompanyId: string
  HeadquarterId: string
  DataBaseName: string
  ExternalId: string
  CreationDate: string
  BackgroundProcessLogId: string
}

interface OTPProviderValueModel {
  signingProcess: ISigningProcess
  setSigningProcess: (value: ISigningProcess) => void
  signatoryData: ISignatoryData
  setSignatoryData: (value: ISignatoryData) => void
}

interface OTPProviderProps {
  children: React.ReactNode
}

const signingProcessDefault = {
  DocumentName: '',
  Guid: '',
  FileUrl: '',
  EntityName: '',
  CompanyOfficeName: '',
  BusinessGroupId: '',
  CompanyId: '',
  HeadquarterId: '',
  DataBaseName: '',
  ExternalId: '',
  BackgroundProcessLogId: '',
  CreationDate: ''
}

const signatoryDataDefault = {
  Id: '',
  SignerUserFullName: '',
  SignerUserFirstName: '',
  SignerUserSecondName: '',
  SignerUserFirstLastName: '',
  SignerUserSecondLastName: ''
}
const OTPContext = React.createContext<OTPProviderValueModel>({
  signingProcess: signingProcessDefault,
  setSigningProcess: () => {
    return
  },
  signatoryData: signatoryDataDefault,
  setSignatoryData: () => {
    return
  }
})

export const useOTPContext = () => React.useContext(OTPContext)

export const OTPProvider: React.FC<OTPProviderProps> = ({ children }) => {
  const [signingProcess, setSigningProcess] = useLocalStorage('signingProcess', signingProcessDefault)

  const [signatoryData, setSignatoryData] = useLocalStorage('signatoryData', signatoryDataDefault)

  return (
    <OTPContext.Provider value={{ signingProcess, setSigningProcess, signatoryData, setSignatoryData }}>
      {children}
    </OTPContext.Provider>
  )
}
