import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'
import TaskIcon from '@mui/icons-material/Task'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import TemplateScreen from '../templateScreen/TemplateScreen'
import BackDropLoadApi from '../backDropLoadApi/BackDropLoadApi'
import { IErrorApi, ErrorApiDefault } from './useValidateOTP'

const buttonStyle = { padding: '0.5rem', margin: '0.5rem' }
export interface IValidateOTPProps {
  documentName: string
  patientFullName: string
  otp: string
  setOtp: (value: string) => void
  isLoading: boolean
  validateVerificationCode: () => void
  isSignedDocument: boolean
  errorApi: IErrorApi
  setErrorApi: (value: IErrorApi) => void
  date: string
  codeFile: string
}

const ValidateOTP: React.FC<IValidateOTPProps> = props => {
  const navigate = useNavigate()
  const handleChange = (newValue: string) => {
    props.setOtp(newValue)
  }

  const validateInputOTP = props.otp.length < 6

  React.useEffect(() => {
    if (props.isSignedDocument) {
      void Swal.fire('Documento Firmado', 'El documento ha sido firmado con exito', 'success').then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          navigate(`/view?id=${props.codeFile}`)
        }
      })
    }
  }, [props.isSignedDocument])

  React.useEffect(() => {
    if (props.errorApi?.errorCode !== 0) {
      void Swal.fire('Error', props.errorApi.messageError, 'error').then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          props.setErrorApi(ErrorApiDefault)
        }
      })
    }
  }, [props.errorApi])

  return (
    <TemplateScreen {...props}>
      <BackDropLoadApi open={props.isLoading} />
      <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: 2 }}>
        <Typography variant='body1' gutterBottom align='center'>
          Ingrese el código de verificación que fue enviado a su numero.
        </Typography>
        <MuiOtpInput value={props.otp} onChange={handleChange} length={6} sx={{ marginY: 2 }} />
        <Button
          size='large'
          disabled={validateInputOTP}
          variant='contained'
          fullWidth
          sx={buttonStyle}
          startIcon={<TaskIcon />}
          onClick={() => {
            props.validateVerificationCode()
          }}
        >
          Firmar
        </Button>
      </Grid>
    </TemplateScreen>
  )
}

export default ValidateOTP
