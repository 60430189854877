import { Button, Grid } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import TaskIcon from '@mui/icons-material/Task'
import { Link } from 'react-router-dom'
import TemplateScreen from '../templateScreen/TemplateScreen'

export interface IMainOTPProps {
  documentName: string
  patientFullName: string
  filePath: string
  date: string
}
const MainOTP: React.FC<IMainOTPProps> = props => {
  const buttonStyle = { padding: '0.5rem', margin: '0.5rem' }
  const buttonGridStyle = { padding: '0.5rem', marginTop: '1rem' }

  return (
    <TemplateScreen {...props}>
      <Grid container direction='column' justifyContent='center' alignItems='center' sx={buttonGridStyle}>
        <Button
          component={Link}
          to={`/view?id=${props.filePath}`}
          state={{ from: 'main' }}
          size='large'
          variant='contained'
          fullWidth
          sx={buttonStyle}
          startIcon={<FileOpenIcon />}
        >
          Ver documento
        </Button>

        <Button
          component={Link}
          to='/validate'
          size='large'
          variant='contained'
          fullWidth
          sx={buttonStyle}
          startIcon={<TaskIcon />}
        >
          Firmar
        </Button>

        <Button
          component={Link}
          to='/decline'
          size='large'
          variant='contained'
          color='error'
          fullWidth
          sx={buttonStyle}
          startIcon={<CloseIcon />}
        >
          Rechazar
        </Button>
      </Grid>
    </TemplateScreen>
  )
}

export default MainOTP
