import { Avatar, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import BackButtonComponent from '../BackButton/BackButton'

interface ITemplateScreenProp {
  documentName: string
  patientFullName: string
  date: string
  children: JSX.Element | JSX.Element[]
}

const paperStyle = { padding: '1rem', margin: '1rem auto' }

const TemplateScreen: React.FC<ITemplateScreenProp> = props => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ marginTop: '1rem', height: '80vh' }}
    >
      <Grid item md={5} xs={12}>
        <Paper elevation={10} style={paperStyle}>
          <BackButtonComponent />
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Avatar style={{ backgroundColor: 'transparent' }} sx={{ width: '5rem', height: '5rem', marginY: '1rem' }}>
              <img style={{ width: '5rem', height: '5rem' }} src='/logo.png' alt='logo-entity' />
            </Avatar>

            <Typography variant='h4' component='h2' align='center'>
              {props.patientFullName !== '' ? `Firma Documento ${props.documentName}` : props.documentName}
            </Typography>
            {props.patientFullName !== '' && (
              <Typography variant='h5' component='h2' align='center'>
                {`${props.patientFullName}`}
              </Typography>
            )}
            {props.date && (
              <Typography variant='h6' align='center'>
                {`${props.date}`}
              </Typography>
            )}
          </Grid>
          {props.children}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TemplateScreen
