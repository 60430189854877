import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DeclineOTPContainer from '../components/declineOTP/DeclineOTPContainer'
import HistoryOTPContainer from '../components/historyOTP/HistoryOTPContainer'
import MainOTPContainer from '../components/main/MainOTPContainer'
import ValidateOTPConatainer from '../components/validateOTP/ValidateOTPContainer'
import ViewPDFContainer from '../components/viewPDF/ViewPDFContainer'
import { OTPProvider } from '../contextProviders/otpProvider'
import NotFound from '../components/NotFound/NotFound'

const RouterApp: React.FC = () => {
  return (
    <OTPProvider>
      <BrowserRouter>
        <Routes>
          <Route path='' element={<MainOTPContainer />} />
          <Route path='/validate' element={<ValidateOTPConatainer />} />
          <Route path='/decline' element={<DeclineOTPContainer />} />
          <Route path='/history' element={<HistoryOTPContainer />} />
          <Route path='/view' element={<ViewPDFContainer />} />
          <Route path='/notfound' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </OTPProvider>
  )
}

export default RouterApp
