import React, { useEffect } from 'react'
import { useOTPContext } from '../../contextProviders/otpProvider'
import DeclineOTP, { IDeclineOTPProps } from './DeclineOTP'
import { UseDeclineOTP } from './useDeclineOTP'

const DeclineOTPContainer: React.FC = () => {
  const { signingProcess, signatoryData } = useOTPContext()
  const { sendDeclineRequest, declineRequest, setDeclineRequest, isLoading, errorApi, setErrorApi, isDeclined, codeFile} =
    UseDeclineOTP()
  useEffect(() => {
    setDeclineRequest({
      ...declineRequest,
      IdGrupoEmpresarial: Number(signingProcess.BusinessGroupId),
      IdEntidad: Number(signingProcess.CompanyId),
      IdSede: Number(signingProcess.HeadquarterId),
      BaseDatos: signingProcess.DataBaseName,
      ExternalId: signingProcess.ExternalId,
      SignerId: Number(signatoryData.Id),
      BackgroundProcessLogId: Number(signingProcess.BackgroundProcessLogId)
    })
  }, [])

  const declineOTPprops: IDeclineOTPProps = {
    documentName: signingProcess.DocumentName,
    patientFullName: signatoryData.SignerUserFullName,
    date: signingProcess.CreationDate,
    declineRequest,
    setDeclineRequest,
    isLoading,
    errorApi,
    setErrorApi,
    isDeclined,
    sendDeclineRequest,
    codeFile
  }
  return <DeclineOTP {...declineOTPprops} />
}

export default DeclineOTPContainer
