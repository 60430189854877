import { Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import Swal from 'sweetalert2'
import formatISO9075 from 'date-fns/formatISO9075'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import TemplateScreen from '../templateScreen/TemplateScreen'
import BackDropLoadApi from '../backDropLoadApi/BackDropLoadApi'
import { ErrorApiDefault, IErrorApi } from '../validateOTP/useValidateOTP'
import { Register } from './useHistoryOTP'

export interface IHistoryOTPProps {
  documentName: string
  patientFullName: string
  date: string
  isLoading: boolean
  errorApi: IErrorApi
  setErrorApi: (value: IErrorApi) => void
  registers: Register[]
}

interface IGetIconRegister {
  stateProcessId: string | undefined
}
const GetIconRegister: React.FC<IGetIconRegister> = ({ stateProcessId }) => {
  switch (stateProcessId) {
    case '0':
      return <ForwardToInboxIcon color={'primary'} />
    case '1':
      return <AssignmentTurnedInIcon color={'success'} />
    case '2':
      return <HighlightOffSharpIcon color={'error'} />
    default:
      return null
  }
}

const HistoryOTP: React.FC<IHistoryOTPProps> = props => {
  React.useEffect(() => {
    if (props.errorApi?.errorCode !== 0) {
      void Swal.fire('Error', props.errorApi.messageError, 'error').then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          props.setErrorApi(ErrorApiDefault)
        }
      })
    }
  }, [props.errorApi])

  return (
    <TemplateScreen {...props}>
      <BackDropLoadApi open={props.isLoading} />
      <Grid container={true} direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={12}>
          <List sx={{ width: '100%' }}>
            {props.registers.map((element, index) => {
              return (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <GetIconRegister stateProcessId={element.StateProcessId} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={element.StateProcessName}
                    data-testid={'test-enviroment-name'}
                    secondary={
                      <>
                        <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                          {`${element.SignerUserFirstName} ${element.SignerUserSecondName} ${element.SignerUserFirstLastName} ${element.SignerUserSecondLastName}`}
                        </Typography>
                        <Typography sx={{ display: 'inline' }} component='caption' variant='body2' color='text.primary'>
                          {` ${element.SignerUserIdentificationType} ${element.SignerUserIdentificationNumber}`}
                        </Typography>
                        <Typography component='caption' variant='body2' color='text.primary'>
                          {`${element.SignerUserEmail}`}
                        </Typography>
                        <Typography component='p' variant='caption' color='text.primary'>
                          {formatISO9075(new Date(element.CreationDate))}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              )
            })}
          </List>
        </Grid>
      </Grid>
    </TemplateScreen>
  )
}

export default HistoryOTP
